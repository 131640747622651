import React from "react";
import { Competition, Group } from "../../global/enums";
import "./GroupTable.scss";
import { TableRow } from "./tableRow";
import { IGame, competitionMap, teamsMap } from "../../data";

export interface IGroupTableProps {
  group?: Group;
  competition: Competition;
  games: IGame[];
}

export const GroupTable = (props: IGroupTableProps) => {
  const { group, competition, games } = props;
  const teamMap = new Map<string, any>();
  const keys = Array.from(teamsMap.keys());
  for (let i in keys) {
    const team = keys[i];
    const teamObj = teamsMap.get(team);
    if (!teamObj?.competitions.includes(competition)) continue;
    let gp = 0;
    let points = 0;
    let wins = 0;
    let losses = 0;
    let draws = 0;
    let gs = 0;
    let gc = 0;
    for (let g = 0; g < games.length; g++) {
      const match = games[g];
      const scoreA = Number(match.scoreA);
      const scoreB = Number(match.scoreB);
      const gameFinished: boolean = match.kickOff < new Date();
      const hasScores: boolean = scoreA !== undefined && scoreB !== undefined;
      // Match title indicates it is a knockout game
      if (!hasScores || match.title || !gameFinished) continue;
      if (match.competition !== competition) continue;
      if (scoreA !== undefined && scoreB !== undefined) {
        if (match.teamA === team) {
          gp++;
          gs += scoreA;
          gc += scoreB;
          if (scoreA > scoreB) {
            wins++;
            points += 3;
          } else if (scoreA === scoreB) {
            draws++;
            points += 1;
          } else {
            losses++;
          }
        }
        if (match.teamB === team) {
          gp++;
          gs += scoreB;
          gc += scoreA;
          if (scoreB > scoreA) {
            wins++;
            points += 3;
          } else if (scoreA === scoreB) {
            draws++;
            points += 1;
          } else {
            losses++;
          }
        }
      }
    }
    const dataMap = new Map<string, number>();
    dataMap.set("gp", gp);
    dataMap.set("gf", gs);
    dataMap.set("ga", gc);
    dataMap.set("gd", gs - gc);
    dataMap.set("points", points);
    dataMap.set("draws", draws);
    dataMap.set("wins", wins);
    dataMap.set("losses", losses);
    teamMap.set(team, dataMap);
  }

  const table: string[] = [];
  for (let i in keys) {
    const team = keys[i];
    const teamObj = teamsMap.get(team);
    if (!teamObj?.competitions.includes(competition)) continue;
    if (competition === Competition.D24) {
      table.push(team);
    }
    if (competition === Competition.D23) {
      table.push(team);
    } else if (
      competition === Competition.I22 &&
      teamObj?.ivies22_group === group
    ) {
      table.push(team);
    } else if (competition === Competition.D22) {
      table.push(team);
    } else if (competition === Competition.NEPL23) {
      table.push(team);
    } else if (
      competition === Competition.I23 &&
      teamObj?.ivies23_group === group
    ) {
      table.push(team);
    }
  }

  const sortedTable = table.sort((a: string, b: string) => {
    // Points
    if (teamMap.get(a).get("points") < teamMap.get(b).get("points")) return 1;
    else if (teamMap.get(a).get("points") > teamMap.get(b).get("points"))
      return -1;
    // Goal difference
    else if (teamMap.get(a).get("gd") < teamMap.get(b).get("gd")) return 1;
    else if (teamMap.get(a).get("gd") > teamMap.get(b).get("gd")) return -1;
    // Goals for
    else if (teamMap.get(a).get("gf") < teamMap.get(b).get("gd")) return 1;
    else if (teamMap.get(a).get("gf") > teamMap.get(b).get("gd")) return -1;
    // Goals against
    else if (teamMap.get(a).get("ga") < teamMap.get(b).get("gd")) return 1;
    else if (teamMap.get(a).get("ga") > teamMap.get(b).get("gd")) return -1;
    // Alphabetical
    else return a.localeCompare(b);
  });

  const rows: JSX.Element[] = [];
  let ind = 0;
  for (let i in sortedTable) {
    rows.push(
      <TableRow
        ind={ind}
        key={i + "team"}
        team={sortedTable[i]}
        data={teamMap.get(sortedTable[i])}
      />
    );
    ind++;
  }

  return (
    <div className="table-container">
      <div className="group-header">
        {competitionMap.get(competition)} {group && group}
      </div>
      <div className="table-headers">
        <div className="rank"></div>
        <div className="l">Club</div>
        <div className="do"></div>
        <div className="do">MP</div>
        <div>W</div>
        <div>D</div>
        <div>L</div>
        <div className="do">GF</div>
        <div className="do">GA</div>
        <div className="do">GD</div>
        <div>Pts</div>
      </div>
      {rows}
    </div>
  );
};
