import { useLocation, useNavigate } from "react-router-dom";
import { ContentType } from "../../global/enums";
import "./Header.scss";
import { IGame } from "../../data";
import { Recents } from "../recents";
import * as ai from "react-icons/ai";
import { useEffect, useRef, useState } from "react";

export interface IHeaderProps {
  games: IGame[];
}

export const Header = (props: IHeaderProps) => {
  const games = props.games;

  let navigate = useNavigate();
  let location = useLocation();
  const pathname = location.pathname.split("/")[1] as ContentType;
  const isHome: boolean = (pathname as ContentType) === ContentType.Home;
  const [leftFade, setLeftFade] = useState<boolean>(false);
  const [rightFade, setRightFade] = useState<boolean>(false);

  const handleScroll = (event: any) => {
    const maxScroll: number =
      event.currentTarget.scrollWidth - event.currentTarget.clientWidth;
    const scrollLeft: number = event.currentTarget.scrollLeft;
    const scrollPercent: number = scrollLeft / maxScroll;
    console.log(scrollPercent);
    if (!scrollPercent) {
      setLeftFade(false);
      setRightFade(false);
    }
    if (scrollPercent > 0.8) setRightFade(false);
    else setRightFade(true);
    if (scrollPercent < 0.2) setLeftFade(false);
    else setLeftFade(true);
  };

  return (
    <div className={`header ${isHome && "home"}`}>
      <div className="title">
        <h1>BMCS</h1>
        <h2>Brown Men's Club Soccer</h2>
      </div>
      {
        <div
          className={`fade start`}
          style={{ opacity: leftFade ? undefined : 0 }}
        />
      }
      {
        <div
          className={`fade end`}
          style={{ width: rightFade ? undefined : 0 }}
        />
      }
      <div className="tabs" onScroll={handleScroll}>
        <div
          className={`tab ${(pathname as ContentType) === ContentType.Home}`}
          onClick={() => navigate("/" + ContentType.Home)}
        >
          Home
        </div>
        <div
          className={`tab ${
            (pathname as ContentType) === ContentType.Fixtures
          }`}
          onClick={() => navigate("/" + ContentType.Fixtures)}
        >
          Fixtures
        </div>
        <div
          className={`tab ${(pathname as ContentType) === ContentType.Results}`}
          onClick={() => navigate("/" + ContentType.Results)}
        >
          Results
        </div>
        <div
          className={`tab ${
            (pathname as ContentType) === ContentType.Standings
          }`}
          onClick={() => navigate("/" + ContentType.Standings)}
        >
          Standings
        </div>
        <div
          className={`tab ${(pathname as ContentType) === ContentType.Stats}`}
          onClick={() => navigate("/" + ContentType.Stats)}
        >
          Stats
        </div>
        <div
          className={`tab ${(pathname as ContentType) === ContentType.Team}`}
          onClick={() => navigate("/" + ContentType.Team)}
        >
          Team
        </div>
        <div
          className={`tab`}
          onClick={() =>
            window.open(
              "https://www.instagram.com/brownmensclubsoccer/",
              "_blank"
            )
          }
          style={{ fontSize: 30, padding: 0, minWidth: 40, height: 40 }}
        >
          <ai.AiFillInstagram />
        </div>
      </div>
      {isHome && <Recents games={props.games} />}
    </div>
  );
};
