import { useState } from "react";
import { Button, MenuPanel } from "../../components";
import { Game } from "../../components/game";
import { IGame } from "../../data";
import { Color, Competition, Team } from "../../global/enums";
import "./Matches.scss";
import { backgroundList } from "../../global/globalUtils";

export interface IMatchProps {
  results: boolean;
  games: IGame[];
  loading: boolean;
}

export const Matches = (props: IMatchProps) => {
  const { results, games, loading } = props;
  const fixtures: JSX.Element[] = [];

  const [activeCompetition, setCurrentCompetition] = useState<
    Competition | undefined
  >(undefined);

  const sorted = games.sort((a, b) => {
    // if fixtures return closest first
    if (results === false) {
      let dateA = new Date(a.kickOff);
      let dateB = new Date(b.kickOff);
      return dateA.getTime() - dateB.getTime();
      // if results return furthest first
    } else {
      let dateA = new Date(a.kickOff);
      let dateB = new Date(b.kickOff);
      return dateB.getTime() - dateA.getTime();
    }
  });

  sorted.forEach((game, ind) => {
    const current = new Date();
    const gameEnd = new Date(game.kickOff!.getTime() + 120 * 60000);
    const backgroundInd = ind % 3;
    if (
      (activeCompetition === undefined ||
        activeCompetition === game.competition) &&
      (game.teamA === Team.Brown || game.teamB === Team.Brown)
    ) {
      if (results === false) {
        if (current < gameEnd)
          fixtures.push(
            <Game
              key={ind + "match"}
              {...game}
              background={backgroundList[backgroundInd].background}
              color={backgroundList[backgroundInd].color}
            />
          );
      } else {
        if (current > gameEnd)
          fixtures.push(
            <Game
              key={ind + "match"}
              {...game}
              background={backgroundList[backgroundInd].background}
              color={backgroundList[backgroundInd].color}
            />
          );
      }
    }
  });

  const getCompetitions = (): JSX.Element[] => {
    if (results === false) {
      return [
        <Button
          dark
          isActive={activeCompetition === undefined}
          onClick={() => setCurrentCompetition(undefined)}
          text={"All"}
        ></Button>,
        <Button
          dark
          isActive={activeCompetition === Competition.D24}
          onClick={() => setCurrentCompetition(Competition.D24)}
          text={"NIRSA '24"}
        ></Button>,
      ];
    } else {
      return [
        <Button
          dark
          isActive={activeCompetition === undefined}
          onClick={() => setCurrentCompetition(undefined)}
          text={"All"}
        ></Button>,
        <Button
          dark
          isActive={activeCompetition === Competition.D24}
          onClick={() => setCurrentCompetition(Competition.D24)}
          text={"NIRSA '24"}
        ></Button>,
        <Button
          dark
          isActive={activeCompetition === Competition.ND24}
          onClick={() => setCurrentCompetition(Competition.ND24)}
          text={"Friendlies"}
        ></Button>,
        <Button
          dark
          isActive={activeCompetition === Competition.D23}
          onClick={() => setCurrentCompetition(Competition.D23)}
          text={"NIRSA '23"}
        ></Button>,
        <Button
          dark
          isActive={activeCompetition === Competition.I23}
          onClick={() => setCurrentCompetition(Competition.I23)}
          text={"Ivies '23'"}
        ></Button>,
        <Button
          dark
          isActive={activeCompetition === Competition.NEPL23}
          onClick={() => setCurrentCompetition(Competition.NEPL23)}
          text={"NEPL '23"}
        ></Button>,
        <Button
          dark
          isActive={activeCompetition === Competition.D22}
          onClick={() => setCurrentCompetition(Competition.D22)}
          text={"NIRSA '22"}
        ></Button>,
        <Button
          dark
          isActive={activeCompetition === Competition.I22}
          onClick={() => setCurrentCompetition(Competition.I22)}
          text={"Ivies '22'"}
        ></Button>,
      ];
    }
  };

  return (
    <div className="matches-container">
      <MenuPanel>{getCompetitions()}</MenuPanel>
      <div className="fixtures">{fixtures}</div>
    </div>
  );
};
