import { Competition, Group } from "../global/enums";

export interface ITeam {
  key: string;
  team_short_name: string;
  team_full_name?: string;
  logo_url: string;
  abbreviation?: string;
  ivies22_group?: Group;
  ivies23_group?: Group;
  competitions: Competition[];
}

export const teams: ITeam[] = [
  {
    key: "brown",
    team_short_name: "Brown",
    team_full_name: "Brown University",
    logo_url: "logos/brown_new.png",
    abbreviation: "BRO",
    ivies22_group: Group.A,
    ivies23_group: Group.A,
    competitions: [
      Competition.D22,
      Competition.I22,
      Competition.NEPL23,
      Competition.I23,
      Competition.D23,
      Competition.D24
    ],
  },
  {
    key: "columbia",
    team_short_name: "Columbia",
    team_full_name: "Columbia University",
    logo_url: "logos/columbia.png",
    abbreviation: "COL",
    ivies22_group: Group.B,
    ivies23_group: Group.A,
    competitions: [Competition.I22, Competition.I23],
  },
  {
    key: "cornell",
    team_short_name: "Cornell",
    team_full_name: "Cornell University",
    logo_url: "logos/cornell.png",
    abbreviation: "COR",
    ivies22_group: Group.A,
    ivies23_group: Group.A,
    competitions: [Competition.I22, Competition.I23],
  },
  {
    key: "dartmouth",
    team_short_name: "Dartmouth",
    team_full_name: "Dartmouth University",
    logo_url: "logos/dartmouth.png",
    abbreviation: "DAR",
    ivies22_group: Group.A,
    ivies23_group: Group.B,
    competitions: [Competition.I22, Competition.I23],
  },
  {
    key: "harvard",
    team_short_name: "Harvard A",
    team_full_name: "Harvard University",
    logo_url: "logos/harvard.png",
    abbreviation: "HAR",
    ivies22_group: Group.B,
    ivies23_group: Group.B,
    competitions: [Competition.I22, Competition.NEPL23, Competition.I23],
  },
  {
    key: "harvardb",
    team_short_name: "Harvard B",
    team_full_name: "Harvard University",
    logo_url: "logos/harvard.png",
    abbreviation: "HAR",
    ivies22_group: Group.B,
    ivies23_group: Group.A,
    competitions: [Competition.NEPL23, Competition.I23],
  },
  {
    key: "penn",
    team_short_name: "Penn",
    team_full_name: "University of Pennsylvania",
    logo_url: "logos/penn.png",
    abbreviation: "PEN",
    ivies22_group: Group.A,
    competitions: [Competition.I22],
  },
  {
    key: "princeton",
    team_short_name: "Princeton",
    team_full_name: "Princeton University",
    logo_url: "logos/princeton.png",
    abbreviation: "PRI",
    ivies22_group: Group.B,
    ivies23_group: Group.B,
    competitions: [Competition.I22, Competition.I23],
  },
  {
    key: "yale",
    team_short_name: "Yale",
    team_full_name: "Yale University",
    logo_url: "logos/yale.png",
    abbreviation: "YAL",
    ivies22_group: Group.B,
    ivies23_group: Group.B,
    competitions: [Competition.I22, Competition.I23],
  },
  {
    key: "bu",
    team_short_name: "BU",
    team_full_name: "Boston University",
    logo_url:
      "https://s.yimg.com/cv/apiv2/default/ncaab/20181221/500x500/boston_u_wbg.png",
    abbreviation: "BU",
    competitions: [Competition.D22, Competition.NEPL23, Competition.D23, Competition.D24],
  },
  {
    key: "bc",
    team_short_name: "BC",
    team_full_name: "Boston College",
    logo_url: "https://a.espncdn.com/i/teamlogos/ncaa/500/103.png",
    abbreviation: "BC",
    competitions: [Competition.D22, Competition.NEPL23, Competition.D23, Competition.D24],
  },
  {
    key: "babson",
    team_short_name: "Babson Green",
    team_full_name: "Babson College",
    logo_url:
      "https://seeklogo.com/images/B/babson-college-logo-85800ECC60-seeklogo.com.png",
    abbreviation: "BAB",
    competitions: [Competition.D22, Competition.NEPL23, Competition.D23, Competition.D24],
  },
  {
    key: "babsonb",
    team_short_name: "Babson White",
    team_full_name: "Babson College",
    logo_url:
      "https://seeklogo.com/images/B/babson-college-logo-85800ECC60-seeklogo.com.png",
    abbreviation: "BAB",
    competitions: [Competition.NEPL23, Competition.D23, Competition.D24],
  },
  {
    key: "nu",
    team_short_name: "Northeastern",
    team_full_name: "Northeastern University",
    logo_url: "logos/northeastern.png",
    abbreviation: "NEU",
    competitions: [Competition.D22, Competition.D23, Competition.D24],
  },
  {
    key: "pc",
    team_short_name: "PC",
    team_full_name: "Providence College",
    logo_url:
      "https://upload.wikimedia.org/wikipedia/en/9/9d/Providence_Friars_logo.svg",
    abbreviation: "PC",
    competitions: [Competition.D22, Competition.D23, Competition.D24],
  },
  {
    key: "bryant",
    team_short_name: "Bryant",
    team_full_name: "Bryant College",
    logo_url: "logos/bryant.png",
    abbreviation: "BRY",
    competitions: [Competition.D22, Competition.NEPL23, Competition.D23, Competition.D24],
  },
  {
    key: "stonehill",
    team_short_name: "Stonehill",
    team_full_name: "Stonehill College",
    logo_url:
      "https://s3-us-west-2.amazonaws.com/scorestream-team-profile-pictures/274932/20190414221908_683_mascotOrig.png",
    abbreviation: "SC",
    competitions: [Competition.D22, Competition.NEPL23],
  },
  {
    key: "stonybrook",
    team_short_name: "Stony Brook",
    team_full_name: "Stony Brook College",
    logo_url: "logos/stonybrook.png",
    abbreviation: "SB",
    competitions: [],
  },
  {
    key: "uconn",
    team_short_name: "UConn",
    team_full_name: "University of Connecticut",
    logo_url: "logos/uconn.png",
    abbreviation: "UC",
    competitions: [],
  },
  {
    key: "uscga",
    team_short_name: "USCGA",
    team_full_name: "United States Coast Guard Academy",
    logo_url:
      "https://upload.wikimedia.org/wikipedia/en/4/49/Coats_Guard_Academy_Bears_Logo.png",
    abbreviation: "USCGA",
    competitions: [Competition.NEPL23],
  },
  {
    key: "merrimack",
    team_short_name: "Merrimack",
    team_full_name: "Merrimack College",
    logo_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Merrimack_Warriors.svg/1200px-Merrimack_Warriors.svg.png",
    abbreviation: "MC",
    competitions: [Competition.NEPL23, Competition.D23, Competition.D24],
  },
  {
    key: "clark",
    team_short_name: "Clark",
    team_full_name: "Clark University",
    logo_url:
      "https://www.ncaa.com/sites/default/files/images/logos/schools/bgl/clark-ma.svg",
    abbreviation: "CU",
    competitions: [Competition.NEPL23],
  },
  {
    key: "colby",
    team_short_name: "Colby",
    team_full_name: "Colby",
    logo_url:
      "https://www.clipartmax.com/png/middle/343-3436616_daytripper-university-colby-college-athletics-logo.png",
    abbreviation: "CO",
    competitions: [Competition.NEPL23],
  },
  {
    key: "bates",
    team_short_name: "Bates",
    team_full_name: "Bates",
    logo_url: "https://gobatesbobcats.com/images/logos/site/site.png",
    abbreviation: "BA",
    competitions: [Competition.NEPL23],
  },
  {
    key: "suffolk",
    team_short_name: "Suffolk",
    team_full_name: "Suffolk University",
    logo_url:
      "https://i.pinimg.com/originals/be/13/75/be1375a0a80cb654ba5ea0c1e8ce7979.png",
    abbreviation: "SU",
    competitions: [],
  },
  {
    key: "williams",
    team_short_name: "Williams",
    team_full_name: "Williams College",
    logo_url: "https://ephsports.williams.edu/images/logos/site/site.png",
    abbreviation: "WC",
    competitions: [Competition.NEPL23],
  },
  {
    key: "tuftsa",
    team_short_name: "TUFC A",
    team_full_name: "Tufts University",
    logo_url:
      "logos/tufts.png",
    abbreviation: "TUA",
    competitions: [Competition.NEPL23],
  },
  {
    key: "tuftsb",
    team_short_name: "TUFC B",
    team_full_name: "Tufts University",
    logo_url:
      "logos/tufts.png",
    abbreviation: "TUB",
    competitions: [Competition.NEPL23],
  },
  {
    key: "bentley",
    team_short_name: "Bentley",
    team_full_name: "Bentley University",
    logo_url: "logos/bentley.png",
    abbreviation: "BENT",
    competitions: [Competition.NEPL23],
  },
  {
    key: "wesleyan",
    team_short_name: "Wesleyan",
    team_full_name: "Wesleyan University",
    logo_url:
      "https://athletics.wesleyan.edu/images/responsive_2020/logo_main.png",
    abbreviation: "WES",
    competitions: [Competition.NEPL23],
  },
  {
    key: "newhaven",
    team_short_name: "New Haven",
    team_full_name: "New Haven University",
    logo_url: "https://newhavenchargers.com/images/logos/site/site.png",
    abbreviation: "NHU",
    competitions: [Competition.NEPL23],
  },
  {
    key: "holycross",
    team_short_name: "Holy Cross",
    team_full_name: "College of the Holy Cross",
    logo_url: "https://goholycross.com/images/logos/site/site.png",
    abbreviation: "HC",
    competitions: [Competition.NEPL23],
  },
  {
    key: "umassw",
    team_short_name: "UMASS W",
    team_full_name: "UMASS White",
    logo_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/UMass_Amherst_athletics_logo.svg/2498px-UMass_Amherst_athletics_logo.svg.png",
    abbreviation: "UMW",
    competitions: [Competition.NEPL23],
  },
  {
    key: "umassm",
    team_short_name: "UMASS M",
    team_full_name: "UMASS Maroon",
    logo_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/UMass_Amherst_athletics_logo.svg/2498px-UMass_Amherst_athletics_logo.svg.png",
    abbreviation: "UMM",
    competitions: [Competition.NEPL23],
  },
  {
    key: "amherst",
    team_short_name: "Amherst",
    team_full_name: "Amherst College",
    logo_url: "https://athletics.amherst.edu/images/logos/site/site.png",
    abbreviation: "AC",
    competitions: [Competition.NEPL23],
  },
  {
    key: "bowdoin",
    team_short_name: "Bowdoin",
    team_full_name: "Bowdoin College",
    logo_url:
      "https://www.nsnsports.net/wp-content/uploads/2016/01/bowdoing_athletics_logo.png",
    abbreviation: "BC",
    competitions: [Competition.NEPL23],
  },
  {
    key: "hult",
    team_short_name: "Hult",
    team_full_name: "Hult Business School",
    logo_url:
      "https://bloximages.newyork1.vip.townnews.com/hudsonstarobserver.com/content/tncms/assets/v3/editorial/7/11/7112e9a8-2d10-11ec-8286-039d4c9b50ba/616862a4ef953.image.png?resize=400%2C400",
    abbreviation: "HBS",
    competitions: [Competition.NEPL23],
  },
  {
    key: "amherst",
    team_short_name: "Amherst",
    team_full_name: "Amherst College",
    logo_url: "https://athletics.amherst.edu/images/logos/site/site.png",
    abbreviation: "AC",
    competitions: [Competition.NEPL23],
  },
  {
    key: "UMLowell",
    team_short_name: "UMass Lowell",
    team_full_name: "UMass Lowell",
    logo_url:
      "https://upload.wikimedia.org/wikipedia/commons/0/02/UMass-Lowell-logo.png",
    abbreviation: "UML",
    competitions: [],
  },
  {
    key: "quinnipiac",
    team_short_name: "Quinnipiac",
    team_full_name: "Quinnipiac University",
    logo_url: "logos/quinnipiac.png",
    abbreviation: "QUI",
    competitions: [],
  },
  {
    key: "brown_alumni",
    team_short_name: "BMCS Alumni",
    team_full_name: "BMCS Alumni",
    logo_url: "logos/brown_alumni.webp",
    abbreviation: "BMCS",
    competitions: [],
  },
  {
    key: "wpi",
    team_short_name: "WPI",
    team_full_name: "Worcester",
    logo_url: "logos/wpi.png",
    abbreviation: "WPI",
    competitions: [],
  }
];

export const teamsMap: Map<string, ITeam> = new Map<string, ITeam>();
for (let i in teams) {
  const team = teams[i];
  teamsMap.set(team.key, team);
}
