import { Team } from "../global/enums";
import { IGame } from "./games";

export interface IPlayer {
  team: Team;
  first_name: string;
  last_name: string;
  photo_url?: string;
  goals_scored?: number;
  clean_sheets?: number;
  games?: IGame[];
  class_of: number;
  positions?: string[];
  alumni?: boolean;
  country?: string;
}

// current players
export let players: IPlayer[] = [
  {
    first_name: "Harper",
    last_name: "",
    team: Team.Brown,
    positions: ["Winger"],
    class_of: 2027,
    // photo_url: "harper.jpg",
  },
  {
    first_name: "Elie",
    last_name: "Papandonatos",
    team: Team.Brown,
    positions: ["Full Back"],
    class_of: 2024,
    photo_url: "elie.jpg",
  },
  {
    first_name: "William",
    last_name: "Kemball-Cook",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2024,
  },
  {
    first_name: "Adam",
    last_name: "Gough",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2024,
    photo_url: "adam.jpg",
  },
  {
    first_name: "Titas",
    last_name: "Grusnius",
    team: Team.Brown,
    positions: ["Goalkeeper"],
    class_of: 2023,
    alumni: true,
    photo_url: "titas.jpg",
  },
  {
    first_name: "Ian",
    last_name: "Balaguera",
    team: Team.Brown,
    positions: ["Full Back", "Winger"],
    class_of: 2024,
    photo_url: "ian.jpg",
  },
  {
    first_name: "Andres",
    last_name: "Beck-Ruiz",
    team: Team.Brown,
    positions: ["Full Back"],
    class_of: 2023,
    alumni: true,
    photo_url: "andres.jpg",
  },
  {
    first_name: "Geireann",
    last_name: "Lindfield Roberts",
    team: Team.Brown,
    positions: ["Winger"],
    class_of: 2023,
    alumni: true,
    photo_url: "geireann.jpg",
    country: "Wales & Austria",
  },
  {
    first_name: "Oscar",
    last_name: "Champigneulle",
    team: Team.Brown,
    positions: ["Center Back"],
    class_of: 2023,
    alumni: true,
    photo_url: "oscar.jpg",
  },
  {
    first_name: "Alex",
    last_name: "Cooper-Hohn",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2024,
  },
  {
    first_name: "Kavon",
    last_name: "Arbabi",
    team: Team.Brown,
    positions: ["Center Back"],
    class_of: 2025,
    photo_url: "kavon.jpg",
  },
  {
    first_name: "Jamey",
    last_name: "Mayer",
    team: Team.Brown,
    positions: ["Striker"],
    class_of: 2025,
    photo_url: "jamey.jpg",
  },
  {
    first_name: "Mehdi",
    last_name: "Epee-Bounya",
    team: Team.Brown,
    positions: ["Full Back"],
    class_of: 2025,
    photo_url: "mehdi.jpg",
  },
  {
    first_name: "Michael",
    last_name: "Yeh",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2023,
    alumni: true,
  },
  {
    first_name: "Ty",
    last_name: "Pham-Swann",
    team: Team.Brown,
    positions: ["Goalkeeper"],
    class_of: 2025,
    photo_url: "ty.jpg",
  },
  {
    first_name: "Ryan",
    last_name: "Chuang",
    team: Team.Brown,
    positions: ["Full Back"],
    class_of: 2023,
    alumni: true,
    photo_url: "ryan.jpg",
  },
  {
    first_name: "Daniel",
    last_name: "Safian",
    team: Team.Brown,
    positions: ["Winger"],
    class_of: 2025,
    photo_url: "dan.jpg",
  },
  {
    first_name: "Tucker",
    last_name: "Lake",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2024,
    photo_url: "tucker.jpg",
  },
  {
    first_name: "Yonas",
    last_name: "Amha",
    team: Team.Brown,
    positions: ["Winger"],
    class_of: 2025,
    photo_url: "yonas.jpg",
  },
  {
    first_name: "Zaul",
    last_name: "Tavangar",
    team: Team.Brown,
    positions: ["Winger"],
    class_of: 2024,
    photo_url: "zaul.jpg",
  },
  {
    first_name: "David",
    last_name: "Han",
    team: Team.Brown,
    positions: ["Left Back"],
    class_of: 2023,
    alumni: true,
  },
  {
    first_name: "Nico",
    last_name: "Perez",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2024,
    photo_url: "nico.jpg",
  },
  {
    first_name: "Nikhil",
    last_name: "Sonthalia",
    team: Team.Brown,
    positions: ["Goalkeeper"],
    class_of: 2026,
    photo_url: "nikhil.jpg",
  },
  {
    first_name: "Miles",
    last_name: "Bryson",
    team: Team.Brown,
    positions: ["Defender"],
    class_of: 2026,
  },
  {
    first_name: "Milo",
    last_name: "O'Donahue",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2026,
  },
  {
    first_name: "Freddie",
    last_name: "O'Donahue",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2027,
  },
  {
    first_name: "Robbie",
    last_name: "Gertsen",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2026,
    photo_url: "robbie.jpg",
  },
  {
    first_name: "Beck",
    last_name: "Kendig",
    team: Team.Brown,
    positions: ["Defender"],
    class_of: 2025,
    photo_url: "beck.jpg",
  },
  {
    first_name: "Dylan",
    last_name: "Parry",
    team: Team.Brown,
    positions: ["Defender"],
    class_of: 2026,
  },
  {
    first_name: "Daven",
    last_name: "Crossland",
    team: Team.Brown,
    positions: ["Forward"],
    class_of: 2024,
  },
  {
    first_name: "Teddy",
    last_name: "Nuttall",
    team: Team.Brown,
    positions: ["Defender"],
    class_of: 2026,
  },
  {
    first_name: "Zach",
    last_name: "Spooner",
    team: Team.Brown,
    positions: ["Forward"],
    class_of: 2025,
  },
  {
    first_name: "Cian",
    last_name: "Carlson",
    team: Team.Brown,
    positions: ["Defender"],
    class_of: 2024,
    photo_url: "cian.jpg",
  },
  {
    first_name: "Justin",
    last_name: "Shin",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2025,
  },
];

// alumni
players = players.concat([
  {
    first_name: "Jaylim",
    last_name: "Aboneaaj",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2022,
    alumni: true,
  },
  {
    first_name: "Peter",
    last_name: "McDermott",
    team: Team.Brown,
    positions: ["Center Back"],
    class_of: 2022,
    alumni: true,
    photo_url: "peter.jpg",
  },
  {
    first_name: "Ethan",
    last_name: "Novak",
    team: Team.Brown,
    positions: ["Forward"],
    class_of: 2022,
    alumni: true,
  },
  {
    first_name: "Jacob",
    last_name: "Gren",
    team: Team.Brown,
    positions: ["Forward"],
    class_of: 2021.5,
    alumni: true,
    photo_url: "jacob.jpg",
  },
  {
    first_name: "Varun",
    last_name: "Mehta",
    team: Team.Brown,
    positions: ["Goalkeeper"],
    class_of: 2022,
    alumni: true,
    photo_url: "varun.jpg",
  },
  {
    first_name: "Nathan",
    last_name: "Plano",
    team: Team.Brown,
    positions: ["Forward"],
    class_of: 2022,
    alumni: true,
    photo_url: "nate.jpg",
  },
  {
    first_name: "Raghav",
    last_name: "Pant",
    team: Team.Brown,
    positions: ["Defender"],
    class_of: 2022,
    alumni: true,
    photo_url: "raghav.jpg",
  },
  {
    first_name: "Philip",
    last_name: "Kendler",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2022,
    alumni: true,
  },
  {
    first_name: "Zach",
    last_name: "Stern",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2022,
    alumni: true,
    photo_url: "zach.jpg",
  },
  {
    first_name: "Nicolas",
    last_name: "Taccone",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2022,
    alumni: true,
  },
  {
    first_name: "Caleb",
    last_name: "Weinstock",
    team: Team.Brown,
    positions: ["Defender"],
    class_of: 2022,
    alumni: true,
  },
  {
    first_name: "Max",
    last_name: "Wills",
    team: Team.Brown,
    positions: ["Midfielder"],
    class_of: 2022,
    alumni: true,
    photo_url: "max.jpg",
  },
]);

export const playersMap: Map<string, IPlayer> = new Map<string, IPlayer>();
for (let i in players) {
  const player = players[i];
  playersMap.set(player.first_name.toLowerCase(), player);
}
