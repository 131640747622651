import { useEffect, useState } from "react";
import { Header } from "../components";
import { ContentType } from "../global/enums";
import { Home, Matches, Standings, Stats, Team } from "../pages";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./Main.scss";
import { getColorMode } from "../global/globalUtils";
import { IGame, getGamesFromSheet } from "../data";

export const Main = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [games, setGames] = useState<IGame[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const content = location.pathname.split("/")[1] as ContentType;
    if (!Object.values(ContentType).includes(content)) {
      navigate("/" + ContentType.Home);
    }

    const getGames = async () => {
      const gamesData = await getGamesFromSheet();
      setGames(gamesData);
      setLoading(false);
    };

    getGames();
  }, [location]);

  return (
    <div className={`container ${getColorMode(isDarkMode)}`}>
      <Header games={games} />
      <div className="content">
        <Routes>
          <Route path={ContentType.Home} element={<Home />} />
          <Route path={ContentType.Team} element={<Team />} />
          <Route
            path={ContentType.Fixtures}
            element={
              <Matches games={games} results={false} loading={loading} />
            }
          />
          <Route
            path={ContentType.Results}
            element={<Matches games={games} results={true} loading={loading} />}
          />
          <Route
            path={ContentType.Standings}
            element={<Standings games={games} loading={loading} />}
          />
          <Route path={ContentType.Stats} element={<Stats games={games} />} />
        </Routes>
      </div>
    </div>
  );
};
