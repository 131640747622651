import { Competition } from "../global/enums";

export const competitionMap = new Map<string, string>();
competitionMap.set(Competition.D24, "NIRSA - Fall 24");
competitionMap.set(Competition.D23, "NIRSA Eastern Mass - Fall 23");
competitionMap.set(Competition.D22, "NIRSA Boston / Providence - Fall 22");
competitionMap.set(Competition.ND23, "Fall 23 Friendly");
competitionMap.set(Competition.I22, "Ivies - Spring 22");
competitionMap.set(Competition.NEPL23, "NEPL - Spring 23");
competitionMap.set(Competition.I23, "Ivies - Spring 23");
