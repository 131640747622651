import React, { useState } from 'react';
import { Button, MenuPanel } from '../../components';
import { PlayerCard } from '../../components';
import { players } from '../../data';
import { Color } from '../../global';
import './Team.scss';

export enum TeamType {
  Alumni = "Alumni",
  Current = "Current Team"
}

export const Team = () => {

  const [currentTeam, setCurrentTeam] = useState<TeamType>(TeamType.Current)


  const sorted = players.sort((a, b) => {
    if (currentTeam === TeamType.Alumni) {
      if (a.class_of === b.class_of) return a.first_name.localeCompare(b.first_name)
      else return b.class_of - a.class_of
    }
    if (a.class_of === b.class_of) return a.first_name.localeCompare(b.first_name)
    else return a.class_of - b.class_of
  });

  return (
    <div className="team-container">
      <MenuPanel>
        <Button dark isActive={currentTeam === TeamType.Current} hoverStyle={"darken"} onClick={() => setCurrentTeam(TeamType.Current)} text={TeamType.Current}></Button>
        <Button dark isActive={currentTeam === TeamType.Alumni} hoverStyle={"darken"} onClick={() => setCurrentTeam(TeamType.Alumni)} text={TeamType.Alumni}></Button>
      </MenuPanel>
      {currentTeam === TeamType.Alumni && <div className="note">If you want to be added to the alumni page please fill out <a href={"https://forms.gle/GVu8Hb2V1swRHzQy8"}>this form.</a></div>}
      <div className="team">
        {sorted.map(player => {
          if((player.alumni && currentTeam === TeamType.Alumni) || (!player.alumni && currentTeam === TeamType.Current)) { return (
            <PlayerCard {...player}/>
          )} else return null
        })}
      </div>
    </div>
  );
};
