import React, { useState } from "react";
import "./MenuPanel.scss";

export interface IMenuPanelProps {
  children: JSX.Element[];
}

export const MenuPanel = (props: IMenuPanelProps) => {
  const { children } = props;
  const [leftFade, setLeftFade] = useState<boolean>(false);
  const [rightFade, setRightFade] = useState<boolean>(false);

  const handleScroll = (event: any) => {
    const maxScroll: number =
      event.currentTarget.scrollWidth - event.currentTarget.clientWidth;
    const scrollLeft: number = event.currentTarget.scrollLeft;
    const scrollPercent: number = scrollLeft / maxScroll;
    if (!scrollPercent) {
      setLeftFade(false);
      setRightFade(false);
    } else if (scrollPercent > 0.8) setRightFade(false);
    else setRightFade(true);
    if (scrollPercent < 0.2) setLeftFade(false);
    else setLeftFade(true);
  };

  return (
    <div style={{ width: "100%", height: "fit-content", position: "relative" }}>
      {
        <div
          className={`fade start`}
          style={{ opacity: leftFade ? undefined : 0 }}
        />
      }
      {
        <div
          className={`fade end`}
          style={{ opacity: rightFade ? undefined : 0 }}
        />
      }
      <div className="menuPanel-container" onScroll={handleScroll}>
        {children.map((child, ind) => {
          return <div key={ind + "menupanel"}>{child}</div>;
        })}
      </div>
    </div>
  );
};
